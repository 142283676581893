<template>
  <div>
    <vx-card>
      <div class="vx-row find-people">
        <div class="vx-col xl:w-full col-cus-12 mb-8 w-full">
          <div class="card-title more-card-title pb-3">
            <h2 class="mb-0">Integration Job Activity</h2>
          </div>
          <div class="flex mb-4 btn-search justify-start flex-wrap items-center">
            <p>Current Connection : {{ connectionID?connectionID.Value:'' }}</p>
            <vs-button
              @click="connectionPopup = true"
              color="success"
              class="sm:mt-0 mt-2 sm:ml-2 ml-0"
              icon-pack="feather"
              >Change Connection
            </vs-button>
          </div>
          <div class="common-table">
            <div class="flex btn-search w-full">
              <h5 class="mb-2 ml-4">Job Activity</h5>
            </div>
            <div class="table-row-select">
              <vs-select
                :label="LabelConstant.inputLabelShowEntries"
                v-model="perPage"
                class="show-select"
                autocomplete
              >
                <vs-select-item
                  :key="index"
                  :value="item.value"
                  :text="item.text"
                  v-for="(item, index) in recordList"
                />
              </vs-select>
            </div>
            <!-- table -->
            <vs-table :data="jobActivityList" :max-item="perPage" pagination search>
              <template slot="thead">
                <vs-th sort-key="Schedule_Name">Schedule Name</vs-th>
                <vs-th sort-key="Activity_Name">Activity</vs-th>
                <vs-th sort-key="Status">Status</vs-th>
                <vs-th sort-key="sortableStartTime">Start Time</vs-th>
                <vs-th sort-key="sortableEndTime">End Time</vs-th>
                <vs-th sort-key="Records">Records</vs-th>
                <vs-th sort-key="Skipped">Skipped</vs-th>
                <vs-th sort-key="Resolved">Resolved</vs-th>
              </template>
              <template slot-scope="{ data }">
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                  <vs-td :data="data[indextr].Schedule_Name">
                    <a @click="jobActivityId = data[indextr].Sync_Job_Activity_Id,jobMainTab =0, getJobActivityDetailRecordList(), getLog()">
                      {{ data[indextr].Schedule_Name }}
                    </a>
                  </vs-td>
                  <vs-td :data="data[indextr].Activity_Name">
                    {{ data[indextr].Activity_Name }}
                  </vs-td>
                  <vs-td :data="data[indextr].Status">
                    {{ data[indextr].Status }}
                  </vs-td>
                  <vs-td :data="data[indextr].sortableStartTime">
                    {{ data[indextr].StartTime|formatDate }}
                  </vs-td>
                  <vs-td :data="data[indextr].sortableEndTime">
                    {{ data[indextr].EndTime|formatDate }}
                  </vs-td>
                  <vs-td :data="data[indextr].Records">
                    {{ data[indextr].Records }}
                  </vs-td>
                  <vs-td :data="data[indextr].Skipped">
                    {{ data[indextr].Skipped }}
                  </vs-td>
                  <vs-td :data="data[indextr].Resolved">
                    {{ data[indextr].Resolved }}
                  </vs-td>
                  <vs-td> </vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </div>
        </div>
      </div>
    </vx-card>
        <!-- select connection popup -->
    <vs-popup class="delete-popup" :active.sync="connectionPopup">
      <template>
        <h2 class="mt-5 pb-4">Target Company</h2>
        <form data-vv-scope="updateConnection">
        <div class="vx-row my-5 px-5 pty-2 text-left">
          <div class="vx-col w-full">
            Select CRM Connection to view
            <v-select
            v-model="connectionID"
            :options="connectionList"
            v-validate="'required'"
            name="connectionID"
            label="Value"
            :clearable="false"
             />
          </div>
           <span class="text-danger error-msg">{{
                errors.first("updateConnection.connectionID")
              }}</span>
        </div>
          </form>
      </template>
      <div class="text-center my-6 py-5 ">
        <vs-button title="Update" color="primary" type="filled" class="mr-2"
          @click="updateConnectionId()">{{ LabelConstant.buttonLabelUpdate }}</vs-button
        >
        <vs-button
          color="grey"
          @click="connectionPopup = false"
          type="border"
          class="ml-4 grey-btn mr-3"
          >{{ LabelConstant.buttonLabelCancel }}</vs-button
        >
      </div>
    </vs-popup>
    <!-- job details popup -->
    <vs-popup
      class="campaign-second popup-width"
      title="Integration Job Detail"
      :active.sync="jobActivityDetailRecordListPopup"
    >
      <template>
        <vs-tabs class="job-details-tabs" v-if="jobActivityDetailRecordList" :value="jobMainTab" >
          <!-- Job Detail -->
          <vs-tab label="Job Detail" @click="jobMainTab = 0">
            <vx-card>
              <div class="vx-row find-people">
                <div class="vx-col xl:w-full col-cus-12 mb-6 w-full">
                  <div class="card-title flex justify-start items-center mb-2">
                    <h4 class="m-0 pr-1">Job Name:</h4>
                    <p>{{jobActivityDetailRecordList.Sync_Job_Name}}</p>
                  </div>
                  <div class="vx-row mb-6 px-4">
                    <div class="vx-col col-cus-12 mb-2 w-full">
                      <div class="flex w-full">
                        <h5>Total Records:</h5>
                        <p>{{jobActivityDetailRecordList.Total_Records|tableRecordNumberFormatter}}</p>
                      </div>
                      <div class="flex">
                        <div class="flex px-4">
                          <h6>{{jobActivityDetailRecordList.CRM_Account_Text}}:</h6>
                          <p>{{jobActivityDetailRecordList.Total_Accounts|tableRecordNumberFormatter}}</p>
                        </div>
                        <div class="flex px-4">
                          <h6>{{jobActivityDetailRecordList.CRM_Contact_Text}}:</h6>
                          <p>{{jobActivityDetailRecordList.Total_Contacts|tableRecordNumberFormatter}}</p>
                        </div>
                        <div class="flex px-4">
                          <h6>{{jobActivityDetailRecordList.CRM_Lead_Text}}:</h6>
                          <p>{{jobActivityDetailRecordList.Total_Leads|tableRecordNumberFormatter}}</p>
                        </div>
                      </div>
                    </div>
                    <div class="vx-col col-cus-12 mb-0 w-full">
                      <div class="flex w-full">
                        <h5>Records Skipped:</h5>
                        <p>{{jobActivityDetailRecordList.Skipped_Records|tableRecordNumberFormatter}}</p>
                      </div>
                      <div class="flex">
                        <div class="flex px-4">
                          <h6>{{jobActivityDetailRecordList.CRM_Account_Text}}:</h6>
                          <p>{{jobActivityDetailRecordList.Skipped_Accounts|tableRecordNumberFormatter}}</p>
                        </div>
                        <div class="flex px-4">
                          <h6>{{jobActivityDetailRecordList.CRM_Contact_Text}}:</h6>
                          <p>{{jobActivityDetailRecordList.Skipped_Contacts|tableRecordNumberFormatter}}</p>
                        </div>
                        <div class="flex px-4">
                          <h6>{{jobActivityDetailRecordList.CRM_Lead_Text}}:</h6>
                          <p>{{jobActivityDetailRecordList.Skipped_Leads|tableRecordNumberFormatter}}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="common-table">
                    <div class="flex btn-search w-full">
                      <vs-checkbox class="ml-4" v-model="showSkippedRows">Show Skipped Rows</vs-checkbox>
                    </div>
                    <!-- table -->
                    <vs-table :data="jobActivityDetailRecordList.JobActivity" pagination search>
                      <template slot="thead">
                        <vs-th sort-key="Module">Module</vs-th>
                        <vs-th sort-key="VisualVisitorID">VisualVisitorID</vs-th>
                        <vs-th sort-key="Account_Name">Account</vs-th>
                        <vs-th sort-key="Name">Name</vs-th>
                        <vs-th sort-key="Sync_Action">Action</vs-th>
                        <vs-th sort-key="Error_Message">Error</vs-th>
                        <vs-th sort-key="Field_Name">Field</vs-th>
                      </template>
                      <template slot-scope="{ data }">
                        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data" v-show="!showSkippedRows || data[indextr].Sync_Action=='Skipped'">
                          <vs-td :data="data[indextr].Module">
                            <span @click="data[indextr].IsDuplicate ?getJobActivityDuplicateReconciliationDetail(data[indextr].VisualVisitorID,data[indextr].Module_Id):redirectTotargetDetailPage(data[indextr].ParentModule,data[indextr].VisualVisitorID)">
                              {{ data[indextr].Module }}
                            </span>
                          </vs-td>
                          <vs-td :data="data[indextr].VisualVisitorID">
                          <span @click="data[indextr].IsDuplicate ?getJobActivityDuplicateReconciliationDetail(data[indextr].VisualVisitorID,data[indextr].Module_Id):redirectTotargetDetailPage(data[indextr].ParentModule,data[indextr].VisualVisitorID)">
                            {{ data[indextr].VisualVisitorID }}
                            </span>
                          </vs-td>
                          <vs-td :data="data[indextr].Account_Name">
                          <span @click="data[indextr].IsDuplicate ?getJobActivityDuplicateReconciliationDetail(data[indextr].VisualVisitorID,data[indextr].Module_Id):redirectTotargetDetailPage(data[indextr].ParentModule,data[indextr].VisualVisitorID)">
                            {{ data[indextr].Account_Name }}
                            </span>
                          </vs-td>
                          <vs-td :data="data[indextr].Name">
                          <span @click="data[indextr].IsDuplicate ?getJobActivityDuplicateReconciliationDetail(data[indextr].VisualVisitorID,data[indextr].Module_Id):redirectTotargetDetailPage(data[indextr].ParentModule,data[indextr].VisualVisitorID)">
                            {{ data[indextr].Name }}
                            </span>
                          </vs-td>
                          <vs-td :data="data[indextr].Sync_Action">
                          <span @click="data[indextr].IsDuplicate ?getJobActivityDuplicateReconciliationDetail(data[indextr].VisualVisitorID,data[indextr].Module_Id):redirectTotargetDetailPage(data[indextr].ParentModule,data[indextr].VisualVisitorID)">
                            {{ data[indextr].Sync_Action }}
                            </span>
                          </vs-td>
                          <vs-td :data="data[indextr].Error_Message">
                          <span @click="data[indextr].IsDuplicate ?getJobActivityDuplicateReconciliationDetail(data[indextr].VisualVisitorID,data[indextr].Module_Id):redirectTotargetDetailPage(data[indextr].ParentModule,data[indextr].VisualVisitorID)">
                            {{ data[indextr].Error_Message }}
                            </span>
                          </vs-td>
                          <vs-td :data="data[indextr].Field_Name">
                          <span @click="data[indextr].IsDuplicate ?getJobActivityDuplicateReconciliationDetail(data[indextr].VisualVisitorID,data[indextr].Module_Id):redirectTotargetDetailPage(data[indextr].ParentModule,data[indextr].VisualVisitorID)">
                            {{ data[indextr].Field_Name }}
                            </span>
                          </vs-td>
                        </vs-tr>
                      </template>
                    </vs-table>
                  </div>
                </div>
              </div>
            </vx-card>
            <div class="text-right w-full float-left mt-6 mb-8">
              <vs-button title="Yes" color="warning" type="filled" class="mr-2" @click="changeTab()"
                >View Logs</vs-button
              >
              <vs-button
                color="grey"
                @click="closeJobActivityDetailRecordList()"
                type="border"
                class="grey-btn mr-3"
                >{{ LabelConstant.buttonLabelCancel }}</vs-button
              >
            </div>
          </vs-tab>
          <!-- Job Logs -->
          <vs-tab label="Job Logs" @click="jobMainTab =1">
            <vx-card>
              <div class="vx-row find-people">
                <div class="vx-col xl:w-full col-cus-12 mb-6 w-full">
                  <div class="card-title flex justify-start items-center mb-2">
                    <h4 class="m-0 pr-1">Job Name:</h4>
                    <p>{{logName}}</p>
                  </div>
                  <div class="">
                   <div class="vx-row">
                      <div class="vx-col w-full relative">
                          <vs-textarea v-model="log" placeholder="Job Logs" rows="8" name="caption">
                          </vs-textarea>
                      </div>
                   </div>
                  </div>
                </div>
              </div>
            </vx-card>
            <div class="text-right w-full float-left mt-6 mb-8">
              <vs-button
                color="grey"
                @click="closeJobActivityDetailRecordList()"
                type="border"
                class="grey-btn mr-3"
                >{{ LabelConstant.buttonLabelCancel }}</vs-button
              >
            </div>
          </vs-tab>
          <!--Record Reconciliation  -->
          <!--<vs-tab label="Record Reconciliation" v-if="showrecordReconciliation">
            <vx-card>
              <div class="vx-row find-people">
                <div class="vx-col xl:w-full col-cus-12 mb-6 w-full">
                  <div class="card-title flex justify-start items-center mb-2">
                    <h4 class="m-0 pr-1">Contact Name:</h4>
                    <p>{{recordReconciliationTargetName}}</p>
                  </div>

                  <div class="">-->
                    <!-- record table -->
                   <!-- <table class="comparision-table white-background table w-full bordered">
                      <thead>
                        <vs-th></vs-th>
                        <vs-th colspan="2"> <h2>VisualVisitor</h2></vs-th>
                        <vs-th> </vs-th>
                        <vs-th colspan="2"> <h2>{{recordReconciliationCRMNameText}}</h2></vs-th>
                      </thead>
                      <thead>
                        <vs-th></vs-th>
                        <vs-th>Field Name</vs-th>
                        <vs-th>Value</vs-th>
                        <vs-th>Issue</vs-th>
                        <vs-th>Field</vs-th>
                        <vs-th>Value</vs-th>
                      </thead>
                      <tbody>
                        <tr v-for="(recordReconciliation, index) in recordReconciliationList" :key="index" v-bind:class="recordReconciliation.Issue == 'Mismatch' ? 'invalid-data' : ''">
                          <td class="w-10">
                            <div class="table-action w-10">
                              <vs-button
                                title="Edit"
                                color="primary"
                                type="filled"
                                icon-pack="feather"
                                icon="icon-edit"
                                class="mr-1"
                                v-if="recordReconciliation.Issue == 'Mismatch'"
                              ></vs-button>
                            </div>
                          </td>
                          <td>{{recordReconciliation.App_Display_Field_Name}}</td>
                          <td v-bind:class="recordReconciliation.Issue == 'Mismatch' ? 'text-danger' : ''">{{recordReconciliation.App_Value}}</td>
                          <td v-bind:class="recordReconciliation.Issue == 'Mismatch' ? 'text-danger' : ''">{{recordReconciliation.Issue}}</td>
                          <td class="bg-fourth">{{recordReconciliation.CRM_Display_Field_Name}}</td>
                          <td class="bg-fourth">{{recordReconciliation.CRM_Value}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </vx-card>
            <div class="text-right w-full float-left mt-6 mb-8">
              <vs-button
                title="Validate"
                color="warning"
                type="filled"
                class="mr-2"
                @click="validateRecord()"
              >Validate</vs-button>
              <vs-button
                title="Update"
                color="primary"
                type="filled"
                class="mr-2"
                @click="updateRecord()"
                >{{ LabelConstant.buttonLabelUpdate }}</vs-button
              >
              <vs-button
                title="Sync Now"
                color="success"
                type="filled"
                class="mr-2"
                @click="syncRecord()"
                >Sync Now</vs-button
              >
              <vs-button
                color="grey"
                @click="closeJobActivityDetailRecordList()"
                type="border"
                class="grey-btn mr-3"
                >{{ LabelConstant.buttonLabelCancel }}</vs-button
              >
            </div>
          </vs-tab>-->
          <!-- Duplicate Reconciliation -->
          <vs-tab label="Duplicate Reconciliation" v-if="showDuplicateReconciliation" >
            <vx-card>
              <div class="vx-row find-people">
                <div class="vx-col xl:w-full col-cus-12 mb-6 w-full">
                  <div class="flex flex-wrap justify-between items-center">
                    <div
                      class="
                        card-title
                        flex
                        justify-start
                        items-center
                        mb-2
                        w-auto
                      "
                    >
                      <h4 class="m-0 pr-1">{{ duplicateTargetName?duplicateTargetName:'' }}:</h4>
                      <p>{{ duplicateReconciliationTargetName }}</p>
                    </div>
                    <div class="mb-2">
                      <vs-button
                        title="Add as New Record"
                        color="success"
                        type="filled"
                        class="mr-2"
                        @click="duplicateReconciliationAddNewRecord()"
                        >Add as New Record</vs-button
                      >
                      <vs-button
                        title="Merge Records"
                        color="warning"
                        type="filled"
                        class="mr-2"
                        @click="mergePopup = true"
                        >Merge Records</vs-button
                      >
                      <vs-button
                        title="Delete Duplicate"
                        color="danger"
                        type="filled"
                        class="mr-2"
                        @click="duplicateReconciliationDeleteRecord()"
                        >Delete Duplicate</vs-button
                      >
                    </div>
                  </div>
                  <div class="table-responsive duplicate-table">
                    <!-- table -->
                    <table
                      class="
                        comparision-table
                        white-background
                        table
                        w-full
                        bordered
                        
                      "
                    >
                      <thead>
                        <vs-th colspan="2"> <h2>Duplicate Record</h2></vs-th>
                        <vs-th :colspan="duplicateTabTableHeader.length-2"> <h2>Existing Record</h2></vs-th>
                      </thead>
                      <thead>
                        <vs-th v-for="(tablehead, index) in duplicateTabTableHeader" :key="index">{{ tablehead }}</vs-th>
                        <!-- <vs-th class="sticky-col first-col">Field Name</vs-th>
                        <vs-th class="sticky-col second-col">Value</vs-th>
                        <vs-th></vs-th>
                        <vs-th>Field</vs-th>
                        <vs-th>Value</vs-th> -->
                      </thead>
                      <tbody>
                        <!-- <tr v-for="(duplicateReconciliation, index) in duplicateReconciliationList" :key="index" v-bind:class="duplicateReconciliation.Is_Identitiy_Field ?'valid-data' : duplicateReconciliation.Is_Mismatch_Field ?'invalid-data': ''">
                          <td  class="sticky-col first-col">{{ duplicateReconciliation.App_Display_Field_Name }}</td>
                          <td class="sticky-col second-col">{{ duplicateReconciliation.App_Value }}</td>
                          <td></td>
                          <td :class="duplicateReconciliation.Read_Only?'bg-fourth':''">{{ duplicateReconciliation.CRM_Display_Field_Name }}</td>
                          <td :class="duplicateReconciliation.Read_Only?'bg-fourth':''">{{ duplicateReconciliation.CRM_Value }}</td>
                        </tr> -->
                        <tr v-for="(duplicateReconciliation, index) in duplicateReconciliationList" :key="index">
                          <td  class="sticky-col first-col">{{ duplicateReconciliation.fieldName }}</td>
                          <td class="sticky-col second-col">{{ duplicateReconciliation.localRecord.Value }}</td>
                          <td v-for="(dup, i) in duplicateCRMDuplicates" :key="i">{{ duplicateReconciliation['duplicate'+i].Value }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </vx-card>
            <div class="text-right w-full float-left mt-6 mb-8">
              <vs-button
                color="grey"
                @click="closeJobActivityDetailRecordList()"
                type="border"
                class="grey-btn mr-3"
                >{{ LabelConstant.buttonLabelCancel }}</vs-button
              >
            </div>
          </vs-tab>
        </vs-tabs>
      </template>
        <!-- merge  popup -->
    <vs-popup class="merge-popup" title="Merge Record" :active.sync="mergePopup">
      <template>
        <form data-vv-scope="updateConnection">
        <div class="vx-row my-5 px-5 pty-2 text-left">
          <div class="vx-col w-full">
           Merge Record with 
            <v-select
            :options="duplicateMergeList"
            name="duplicateMergeId"
            label="Value"
            v-model="duplicateMergeId"
            :clearable="false"
             />
          </div>
        </div>
          </form>
      </template>
      <div class="text-center my-6 py-5 ">
        <vs-button title="Update" color="primary" type="filled" class="mr-2"
         @click="mergeDuplicateRecord()">{{ LabelConstant.buttonLabelUpdate }}</vs-button
        >
        <vs-button
          color="grey"
          @click="mergePopup = false"
          type="border"
          class="ml-4 grey-btn mr-3"
          >{{ LabelConstant.buttonLabelCancel }}</vs-button
        >
      </div>
    </vs-popup>
    </vs-popup>      
  </div>
</template>
<script>
import vSelect from "vue-select";
import moment from "moment";

export default {
  components: {
    "v-select": vSelect,
  },
  filters: {
    formatDate(val) {
      return moment(val).format("YYYY-MM-DD HH:mm A");
    },
  },
  data: () => ({
    perPage: 5,
    connectionID:null,
    connectionPopup:false,
    mergePopup:false,
    connectionList:[],
    recordList: [
      { text: "5", value: 5 },
      { text: "10", value: 10 },
      { text: "25", value: 25 },
      { text: "50", value: 50 },
      { text: "100", value: 100 },
    ],
    jobMainTab:0,
    jobActivityList: [],

    jobActivityId: null,
    showSkippedRows: false,
    jobActivityDetailRecordList: null,
    jobActivityDetailRecordListPopup: false,

    logName: null,
    log: "",

    showrecordReconciliation:false,
    visualVisitorId: null,
    recordReconciliationTargetName: null,
    recordReconciliationCRMNameText: null,
    recordReconciliationList: [],

    showDuplicateReconciliation:false,
    duplicateVisualVisitorId: null,
    duplicateModuleId:null,
    duplicateReconciliationList: null,
    duplicateCRMDuplicates:[],
    duplicateReconciliationTargetName: null,
    duplicateReconciliationCRMNameText:[],
    duplicateTabTableHeader:[],
    duplicateMergeList : [],
    duplicateMergeId:null,
    duplicateTargetName : null,
  }),
  created(){
    this.getConnectionList();
    //this.getJobActivity();
  },
  methods:{
    updateConnectionId(){
      this.$validator.validateAll("updateConnection").then(async result => {
        if (result) {
          this.connectionPopup=false;
          this.getJobActivity();
        }
      });
    },
    getJobActivity(){
      this.$vs.loading();
      this.axios.get("/ws/IntegrationJobActivity/GetJobActivity?connection_Id="+this.connectionID.Key).then((response) => {
        let data = response.data;
        this.jobActivityList = data;
        this.jobActivityList.map(function (manipulatedData) {
          manipulatedData.sortableStartTime = new Date(manipulatedData.StartTime);
          manipulatedData.sortableEndTime = new Date(manipulatedData.EndTime);
          return manipulatedData;
        });
        this.jobActivityList.sort(this.GetSortOrder("sortableStartTime", "DESC"));
        this.$vs.loading.close();
      }).catch((e) => {
        this.$vs.loading.close();
        this.$vs.notify({
          title: "Error",
          text: e.response.data.Errors[0].Message,
          color: "danger",
          iconPack: "feather",
          icon: "icon-alert-circle",
          position: "top-right",
          time: 4000,
        });
        this.showError(e);
      });  
    },
    getJobActivityDetailRecordList(){
      this.$vs.loading();
      this.axios.get("/ws/IntegrationJobActivityDetail/GetJobActivityRecordList?sync_Job_Activity_Id="+this.jobActivityId).then((response) => {
        let data = response.data;
        this.jobActivityDetailRecordList = data;
        this.jobActivityDetailRecordListPopup = true
        this.$vs.loading.close();
      }).catch((e) => {
        this.$vs.loading.close();
        this.$vs.notify({
          title: "Error",
          text: e.response.data.Errors[0].Message,
          color: "danger",
          iconPack: "feather",
          icon: "icon-alert-circle",
          position: "top-right",
          time: 4000,
        });
        this.showError(e);
      });
    },
    closeJobActivityDetailRecordList(){
      this.jobActivityDetailRecordListPopup = false
      this.jobActivityDetailRecordList = null
      this.jobActivityId = null
      this.logName = null
      this.log = ""
      this.visualVisitorId = null
      this.recordReconciliationList = []
      this.recordReconciliationTargetName = null
      this.recordReconciliationCRMNameText = null
      this.showDuplicateReconciliation = false
      this.showrecordReconciliation = false
      this.showDuplicateReconciliation=false;
      this.duplicateVisualVisitorId= null;
      this.duplicateModuleId=null;
      this.duplicateReconciliationList= null;
      this.duplicateCRMDuplicates=[];
      this.duplicateReconciliationTargetName= null;
      this.duplicateReconciliationCRMNameText=[];
      this.duplicateTabTableHeader=[];
      this.duplicateMergeList = [];
      this.duplicateMergeId=null;
      this.duplicateTargetName = null;
    },
    getLog(){
      this.$vs.loading();
      this.axios.get("/ws/IntegrationJobActivityRecordLog/GetLog?sync_Job_Activity_Id="+this.jobActivityId).then((response) => {
        let data = response.data;
        this.logName = data.Sync_Job_Name
        let tempLog = data.Log
        for (let i = 0; i < tempLog.length; i++) {
          this.log = this.log + tempLog[i].Event_Date_Time+' '+tempLog[i].Event_Level+' '+tempLog[i].Event_Description+'\n'
        }
        this.$vs.loading.close();
      }).catch((e) => {
        this.$vs.loading.close();
        this.$vs.notify({
          title: "Error",
          text: e.response.data.Errors[0].Message,
          color: "danger",
          iconPack: "feather",
          icon: "icon-alert-circle",
          position: "top-right",
          time: 4000,
        });
        this.showError(e);
      });
    },
    getJobActivityRecordReconciliationDetail(id){
      this.$vs.loading();
      this.axios.get("/ws/IntegrationJobActivityRecordReconciliation/GetJobActivityRecordDetail?sync_Job_Activity_Detail_Id="+id).then((response) => {
        let data = response.data;
        this.showDuplicateReconciliation = false;
        this.showrecordReconciliation = true;
        setTimeout(() => {  
          this.jobMainTab = 2;
        }, 100);
        this.visualVisitorId = data.VisualVisitorID
        this.recordReconciliationList = data.JobActivity
        this.recordReconciliationTargetName = data.Target_Name
        this.recordReconciliationCRMNameText = data.CRM_Name_Text
        this.$vs.loading.close();
      }).catch((e) => {
        this.$vs.loading.close();
        this.$vs.notify({
          title: "Error",
          text: e.response.data.Errors[0].Message,
          color: "danger",
          iconPack: "feather",
          icon: "icon-alert-circle",
          position: "top-right",
          time: 4000,
        });
        this.showError(e);
      });  
    },
    validateRecord(){
      let requestAppFieldPairParams = []
      for (let i = 0; i < this.jobActivityDetailRecordList.length; i++) {
        requestAppFieldPairParams.push({
          Key: this.jobActivityDetailRecordList[i].App_API_Field_Name,
          Value: this.jobActivityDetailRecordList[i].App_Value
        })
      }
      let requestParams = {
        VisualVisitorID: this.visualVisitorId,
        App_Field_Pair: this.requestAppFieldPairParams
      }
      this.$vs.loading();
      this.axios.post("/ws/IntegrationJobActivityRecordReconciliation/ValidateRecord",requestParams).then((response) => {
        let data = response.data
        if(data.Transaction_Successful){
          this.$vs.notify({
            title: "Success",
            text: data.Message,
            color: "success",
            iconPack: "feather",
            icon: "icon-check",
            position: "top-right",
            time: 4000,
          });  
        }else{
          this.$vs.notify({
            title: "Error",
            text: data.Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
            time: 4000,
          });
        }
        this.$vs.loading.close();
      }).catch((e) => {
        this.$vs.loading.close();
        this.$vs.notify({
          title: "Error",
          text: e.response.data.Errors[0].Message,
          color: "danger",
          iconPack: "feather",
          icon: "icon-alert-circle",
          position: "top-right",
          time: 4000,
        });
        this.showError(e);
      });   
    },
    updateRecord(){
      let requestAppFieldPairParams = []
      for (let i = 0; i < this.jobActivityDetailRecordList.length; i++) {
        requestAppFieldPairParams.push({
          Key: this.jobActivityDetailRecordList[i].App_API_Field_Name,
          Value: this.jobActivityDetailRecordList[i].App_Value
        })
      }
      let requestParams = {
        VisualVisitorID: this.visualVisitorId,
        App_Field_Pair: this.requestAppFieldPairParams
      }
      this.$vs.loading();
      this.axios.post("/ws/IntegrationJobActivityRecordReconciliation/UpdateRecord",requestParams).then((response) => {
        let data = response.data
        if(data.Transaction_Successful){
          this.$vs.notify({
            title: "Success",
            text: data.Message,
            color: "success",
            iconPack: "feather",
            icon: "icon-check",
            position: "top-right",
            time: 4000,
          });  
          this.closeJobActivityDetailRecordList();
          this.getJobActivity();        
        }else{
          this.$vs.notify({
            title: "Error",
            text: data.Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
            time: 4000,
          });
        }
        this.$vs.loading.close();
      }).catch((e) => {
        this.$vs.loading.close();
        this.$vs.notify({
          title: "Error",
          text: e.response.data.Errors[0].Message,
          color: "danger",
          iconPack: "feather",
          icon: "icon-alert-circle",
          position: "top-right",
          time: 4000,
        });
        this.showError(e);
      });   
    },
    syncRecord(){
      let requestAppFieldPairParams = []
      for (let i = 0; i < this.jobActivityDetailRecordList.length; i++) {
        requestAppFieldPairParams.push({
          Key: this.jobActivityDetailRecordList[i].App_API_Field_Name,
          Value: this.jobActivityDetailRecordList[i].App_Value
        })
      }
      let requestParams = {
        VisualVisitorID: this.visualVisitorId,
        App_Field_Pair: this.requestAppFieldPairParams
      }
      this.$vs.loading();
      this.axios.post("/ws/IntegrationJobActivityRecordReconciliation/SyncRecord",requestParams).then((response) => {
        let data = response.data
        if(data.Transaction_Successful){
          this.$vs.notify({
            title: "Success",
            text: data.Message,
            color: "success",
            iconPack: "feather",
            icon: "icon-check",
            position: "top-right",
            time: 4000,
          });
          this.closeJobActivityDetailRecordList();
          this.getJobActivity();  
        }else{
          this.$vs.notify({
            title: "Error",
            text: data.Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
            time: 4000,
          });
        }
        this.$vs.loading.close();
      }).catch((e) => {
        this.$vs.loading.close();
        this.$vs.notify({
          title: "Error",
          text: e.response.data.Errors[0].Message,
          color: "danger",
          iconPack: "feather",
          icon: "icon-alert-circle",
          position: "top-right",
          time: 4000,
        });
        this.showError(e);
      });   
    },
    getJobActivityDuplicateReconciliationDetail(vid,duplicatemoduleId){
      let input = {
        "Connection_Id": this.connectionID.Key,
        "VisualVisitorId": vid,
        "Module_Id": duplicatemoduleId,
      };
      this.$vs.loading();
      this.axios.post("/ws/IntegrationJobActivityDuplicateReconciliation/GetRecordDuplicateDetail",input).then((response) => {
        let data = response.data;
        this.showDuplicateReconciliation = true;
        setTimeout(() => {  
          this.jobMainTab = 2;
        }, 100);
        this.duplicateTabTableHeader = data.TableHeaders;
        this.duplicateMergeList = data.DuplicateMergeList;
        this.duplicateTargetName = data.TargetLabelText;
        let finalData = [];
        let fieldName = data.FieldNames;
        fieldName.forEach((element,index) => {
          let newobj = {
            fieldName : element,
            localRecord : data.LocalTargetRecord[index],
          }
          data.CRMDuplicates.forEach((ele,i) => {
            let newName = 'duplicate'+i;
            newobj[newName] = ele[index];
          });
          finalData.push(newobj);
        });
        this.duplicateReconciliationList = finalData;
        this.duplicateCRMDuplicates = data.CRMDuplicates;
        this.duplicateVisualVisitorId = vid;
        this.duplicateModuleId = duplicatemoduleId;
        // this.duplicateReconciliationList = data.JobActivity;
        this.duplicateReconciliationTargetName = data.Target_Name;
        // this.duplicateReconciliationCRMNameText = data.CRM_Name_Text;
        this.$vs.loading.close();
      }).catch((e) => {
        this.$vs.loading.close();
        this.$vs.notify({
          title: "Error",
          text: e.response.data.Errors[0].Message,
          color: "danger",
          iconPack: "feather",
          icon: "icon-alert-circle",
          position: "top-right",
          time: 4000,
        });
        this.showError(e);
      }); 
    },
    duplicateReconciliationAddNewRecord(){
      this.$vs.loading();
      let input = {
          "Connection_Id": this.connectionID.Key,
          "VisualVisitorId": this.duplicateVisualVisitorId,
          "Module_Id": this.duplicateModuleId
      };
      this.axios.post("/ws/IntegrationJobActivityDuplicateReconciliation/AddRecord",input).then((response) => {
        let data = response.data;
        if(data.Transaction_Successful){
          this.$vs.notify({
            title: "Success",
            text: data.Message,
            color: "success",
            iconPack: "feather",
            icon: "icon-check",
            position: "top-right",
            time: 4000,
          });
          this.closeJobActivityDetailRecordList();
          this.getJobActivity();  
        }else{
          this.$vs.notify({
            title: "Error",
            text: data.Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
            time: 4000,
          });
        }
        this.$vs.loading.close();
      }).catch((e) => {
        this.$vs.loading.close();
        this.$vs.notify({
          title: "Error",
          text: e.response.data.Errors[0].Message,
          color: "danger",
          iconPack: "feather",
          icon: "icon-alert-circle",
          position: "top-right",
          time: 4000,
        });
        this.showError(e);
      });
    },
    duplicateReconciliationDeleteRecord(){
      this.$vs.loading();
      let input = {
          "Connection_Id": this.connectionID.Key,
          "VisualVisitorId": this.duplicateVisualVisitorId,
          "Module_Id": this.duplicateModuleId
      };
      this.axios.post("/ws/IntegrationJobActivityDuplicateReconciliation/DeleteRecord",input).then((response) => {
        let data = response.data;
        if(data.Transaction_Successful){
          this.$vs.notify({
            title: "Success",
            text: data.Message,
            color: "success",
            iconPack: "feather",
            icon: "icon-check",
            position: "top-right",
            time: 4000,
          });
          this.closeJobActivityDetailRecordList();
          this.getJobActivity();  
        }else{
          this.$vs.notify({
            title: "Error",
            text: data.Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
            time: 4000,
          });
        }
        this.$vs.loading.close();
      }).catch((e) => {
        this.$vs.loading.close();
        this.$vs.notify({
          title: "Error",
          text: e.response.data.Errors[0].Message,
          color: "danger",
          iconPack: "feather",
          icon: "icon-alert-circle",
          position: "top-right",
          time: 4000,
        });
        this.showError(e);
      });
    },
    changeTab(){
      this.jobMainTab = 1;
    },
    async getConnectionList() {
      this.$vs.loading();
      await this.axios
        .get("/ws/IntegrationJobActivity/GetConnectionIds")
        .then((response) => {
          let data = response.data; 
          this.connectionList = data;
          if(data.length > 1){
              this.connectionPopup = true;
          } else {
            this.connectionID = data[0];
            this.getJobActivity();
          }
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    redirectTotargetDetailPage(targetModule,id){
      this.jobActivityDetailRecordListPopup=false;
      if(targetModule == 'company'){
        setTimeout(() => {
          this.$router.push({ name: 'TargetCompanyList', query: { conId: this.connectionID.Key, compId:id } })
        }, 100);
      } else {
        setTimeout(() => {
          this.$router.push({ name: 'TargetContactList', query: { conId: this.connectionID.Key, compId:id } })
      }, 100);
      }
    },
    mergeDuplicateRecord(){
      this.$vs.loading();
      let input = {
          "Connection_Id": this.connectionID.Key,
          "VisualVisitorId": this.duplicateVisualVisitorId,
          "Module_Id": this.duplicateModuleId,
          "CRM_Id":this.duplicateMergeId.Key
      };
      this.axios.post("/ws/IntegrationJobActivityDuplicateReconciliation/MergeRecord",input).then((response) => {
        let data = response.data;
        if(data.Transaction_Successful){
          this.$vs.notify({
            title: "Success",
            text: data.Message,
            color: "success",
            iconPack: "feather",
            icon: "icon-check",
            position: "top-right",
            time: 4000,
          });
          this.mergePopup = false;
          this.closeJobActivityDetailRecordList();
          this.getJobActivity();  
        }else{
          this.$vs.notify({
            title: "Error",
            text: data.Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
            time: 4000,
          });
        }
        this.$vs.loading.close();
      }).catch((e) => {
        this.$vs.loading.close();
        this.$vs.notify({
          title: "Error",
          text: e.response.data.Errors[0].Message,
          color: "danger",
          iconPack: "feather",
          icon: "icon-alert-circle",
          position: "top-right",
          time: 4000,
        });
        this.showError(e);
      });
    },
  }
};
</script>